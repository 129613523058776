import React from "react"
import Layout from "../components/layout"
import style from "../styles/sites.module.css"
import Infocard from "../components/infocard"
import { graphql } from "gatsby"
import SEO from "../components/seo"

function hands({ data }) {
  return (
    <Layout>
      <SEO
        title="Hands"
        description="Gönne deinem Körper eine Luxuspflege! Alle meine Produkte kannst du in einem kostenlosen und unverbindlichen Termin kennenlernen!"
      />
      <h1>Schöne und gepflegte Hände sind deine Visitenkarte</h1>
      <p>
        Ob Gel, Acryl, Nachfüllungen, Verstärkung des Naturnagels, Maniküre oder
        Sonderlackierungen - ich setze deine Vorstellungen gerne um.
      </p>
      <section className={style.services}>
        {data.hands.nodes.map(item => {
          return <Infocard data={item} key={item.id} />
        })}
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    hands: allContentfulHands(sort: { order: ASC, fields: order }) {
      nodes {
        text {
          childMarkdownRemark {
            html
          }
        }
        title
        id
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
export default hands
